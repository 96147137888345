import React, { useState, useEffect, ChangeEvent, MouseEvent, KeyboardEvent } from "react";
import { useRouter } from "next/navigation";
import CryptoJS from "crypto-js";
import { Checkbox } from "antd";
import InputField from "../../Atoms/inputField";
import PrimaryButton from "../../Atoms/primaryButton";
import serviceUtil from "../../../Utils/serviceUtil/serviceUtil";
import { getErrorMessage } from "../../../Utils/messageUtils";
import CustomModal from "../../Atoms/CustomModal";
import Cookies from "js-cookie";
import { logout } from "../../../Utils/logoutUtil";
import Link from "next/link";
import { useTranslation } from "react-i18next";

interface LoginBoxProps {
  apiUrl: string;
}

const LoginBox: React.FC<LoginBoxProps> = ({ apiUrl }) => {
  const router = useRouter();
  const { t } = useTranslation();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [description, setDescription] = useState({
    message: "",
    type: "",
    heading: "",
  });
  const [usernameError, setUsernameError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [ButtonTitle, setButtonTitle] = useState("");
  const [iconUrl, setIconUrl] = useState("");
  const [activeSession, setActiveSession] = useState<boolean>(false);

  useEffect(() => {
    const userInActivityTimeout = localStorage.getItem("USER_INACTIVITY_TIMEOUT");
    if (userInActivityTimeout === "true") {
      setDescription(getErrorMessage("USER_INACTIVITY_TIMEOUT"));
      setIconUrl("/error.svg");
      setButtonTitle(t("Ok"));
      setIsModalVisible(true);
      localStorage.removeItem("USER_INACTIVITY_TIMEOUT");
    }
    const storedUsername = localStorage.getItem("username");
    const storedPassword = localStorage.getItem("password");
    const storedRememberMe = localStorage.getItem("rememberMe") === "true";

    if (storedUsername && storedPassword) {
      const encryptionKey =
        process.env.NEXT_PUBLIC_ENCRYPTION_KEY ||
        "B374A26A71490437AA024E4FADD5B497FDFF1A8EA6FF12F6FB65AF2720B59CCF";
      const decryptedPassword = CryptoJS.AES.decrypt(
        storedPassword,
        encryptionKey
      ).toString(CryptoJS.enc.Utf8);
      setUsername(storedUsername);
      setPassword(decryptedPassword);
    }
    setRememberMe(storedRememberMe);
  }, []);

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    if (emailValue && !validateEmail(emailValue)) {
      setUsernameError(t("Email format is invalid."));
    } else {
      setUsernameError("");
    }
  };

  const handleLogout = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setActiveSession(false);
    logout();
  };

  const handleLoginClick = async (event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    let isValid = true;

    if (!username) {
      setUsernameError(t("Please enter your email id."));
      isValid = false;
    } else if (username && !validateEmail(username)) {
      isValid = false;
    } else {
      setUsernameError("");
    }

    if (!password) {
      setPasswordError(t("Please enter a password."));
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (isValid) {
      const encryptionKey =
        process.env.NEXT_PUBLIC_ENCRYPTION_KEY ||
        "B374A26A71490437AA024E4FADD5B497FDFF1A8EA6FF12F6FB65AF2720B59CCF";
      const encryptedPassword = CryptoJS.AES.encrypt(password, encryptionKey).toString();
      if (rememberMe) {
        localStorage.setItem("username", username);
        localStorage.setItem("password", encryptedPassword);
        localStorage.setItem("rememberMe", "true");
      } else {
        localStorage.removeItem("username");
        localStorage.removeItem("rememberMe");
        localStorage.removeItem("password");
      }
      setLoading(true);
      try {
        const response = await serviceUtil.post(apiUrl, { email: username, password: encryptedPassword }, {}, true);

        if (response?.status === 200 || response?.status === 201) {
          localStorage.setItem("loginTime", new Date().getTime().toString());
          localStorage.setItem("accessToken", response.data?.data?.accessToken);
          Cookies.set("accessToken", response.data?.data?.accessToken, { expires: 1, secure: true, sameSite: "Strict" });
          setLoading(false);
          localStorage.setItem("email", response.data.data?.email);
          localStorage.setItem("firstName", response.data.data?.firstName);
          localStorage.setItem("lastName", response.data.data?.lastName);
          checkSession(response.data?.data?.accessToken);
        } else {
          setLoading(false);
          setIsModalVisible(true);
          if (response.error.response.data.message === "LOGIN_ACTIVE_TOKEN_FOUND_LOGOUT_TO_CONTINUE") {
            setIconUrl("/error.svg");
            setButtonTitle(t("Logout All"));
            setActiveSession(true);
            localStorage.setItem("accessToken", response.error.response.data.token);
            Cookies.set("accessToken", response.error.response.data.token, { expires: 1, secure: true, sameSite: "Strict" });
            setDescription(getErrorMessage(response.error.response.data.message));
          } else {
            setIconUrl("/error.svg");
            setDescription(getErrorMessage(response.error.response.data.message));
            setButtonTitle(t("Ok"));
          }
          console.error(response.error);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
      }
    }
  };

  const checkSessionTimeout = () => {
    const SessionTimeoutMinutes = 600;
    const SessionTimeoutMilliseconds = SessionTimeoutMinutes * 60 * 1000;
    const loginTime = localStorage.getItem("loginTime");
    if (loginTime) {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - parseInt(loginTime, 10);

      if (elapsedTime >= SessionTimeoutMilliseconds) {
        logout();
      }
    }
  };

  const checkSession = async (accessToken: string) => {
    const interval = setInterval(() => {
      checkSessionTimeout();
    }, 60000);


    if (!accessToken) {
      logout();
    } else {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        lang: localStorage.getItem('languageSelected') ? localStorage.getItem('languageSelected') : 'en'
      };

      try {
        const response = await serviceUtil.get(
          `${process.env.NEXT_PUBLIC_API_URL}/identity/user/menu`, {}, headers,
        );

        if (response?.status === 200) {
          localStorage.setItem("userMenu", JSON.stringify(response.data.userData));
          router.push("/dashboard");
        }
        else{
          setDescription(getErrorMessage(response.error.response.data.message));
        }
      } catch (error) {
        if (error?.response?.data?.statusCode === 401) {
        }
      }
    }

    return () => clearInterval(interval);
  };


  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleRegisterClick = () => {
    router.push("/auth/registration");
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleLoginClick(e);
    }
  }

  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleRememberMeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  return (
    <div>
      <div className="mb-4 md:mb-2 lg:mb-3">
        <InputField
          inputType="email"
          value={username}
          onChange={handleUsernameChange}
          placeholder={t("Username")}
          prefixIcon={"/mail.svg"}
          title={t("Email")}
          infoText={t("Enter your email")}
          validate
          onBlur={handleEmailBlur}
          onKeyDown={handleKeyDown}
          errorMessage={usernameError}
        />
      </div>
      <div className="mb-4 md:mb-2 lg:mb-3">
        <InputField
          inputType="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder={t("Password")}
          prefixIcon={"/password.svg"}
          title={t("Password")}
          infoText={t("Enter your password")}
          onKeyDown={handleKeyDown}
          validate={false}
          errorMessage={passwordError}
        />
      </div>
      <div className="mb-4 md:mb-2 lg:mb-3">
        <div className="flex justify-between items-center">
          <Checkbox checked={rememberMe} onChange={handleRememberMeChange}>
            {t("Remember Me")}
          </Checkbox>
          <Link
            href="/auth/forgot-password"
            className="text-blue-500 hover:underline"
          >
            {t("Forgot Password?")}
          </Link>
        </div>
      </div>
      <div className="mb-4 md:mb-2 lg:mb-3">
        <PrimaryButton
          theme="primary"
          primaryColor={{
            backgroundColor: "#1D4E89",
            color: "#FFFFFF",
            borderColor: "#1D4E89",
          }}
          disabled={loading}
          height={"7vmin"}
          onClick={handleLoginClick}
          className="w-full text-sm sm:text-base md:text-md lg:text-lg xxl:text-xl font-medium"
        >
          {loading ? t("Signing In...") : t("Sign In")}
        </PrimaryButton>
      </div>
      <div className="mb-2 text-black">
        {t("Don't have an existing account?")}
      </div>
      <div className="mb-4 md:mb-2 lg:mb-3">
        <PrimaryButton
          theme="secondary"
          secondaryColor={{
            backgroundColor: "transparent",
            color: "#0298A0",
            borderColor: "#0298A0",
          }}
          disabled={false}
          height={"7vmin"}
          onClick={handleRegisterClick}
          className="w-full text-sm sm:text-base md:text-md lg:text-lg xxl:text-xl font-medium"
        >
          {t("Sign Up")}
        </PrimaryButton>
      </div>
      <CustomModal
        isVisible={isModalVisible}
        onCancel={closeModal}
        onOk={
          activeSession
            ? (e: React.MouseEvent<HTMLButtonElement>) => handleLogout(e)
            : closeModal
        }
        title={description?.heading}
        description={description?.message}
        iconType={description?.type}
        primaryButtonText={ButtonTitle}
        secondaryButtonText=""
      />
    </div>
  );
};

export default LoginBox;
