"use client";
import React from "react";
import LoginBox from "../../Molecules/LoginBox";
import DynamicBox from "../DynamicBox/DynamicBox";

interface LoginProps {
  apiUrl: string;
}

const LoginContainer: React.FC<LoginProps> = ({ apiUrl }) => {
  return (
    <DynamicBox
      title="Hello User! Welcome to Dekarb"
      subtitle="Carbon Footprint Calculator"
      text=""
    >
      <LoginBox apiUrl={apiUrl} />
    </DynamicBox>
  );
};

export default LoginContainer;
